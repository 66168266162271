@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

body {
  font-family: "Lilita One", sans-serif;
}

/* Add any other custom styles for your app here */

pre {
  font-size: 2.3vw;
}

.code {
  width: 100%;
}
.code-block {
  background-color: #222;
  color: #fff;
  padding: 20px;
  font-family: monospace;
  font-size: 18px;
  line-height: 1.5;
  white-space: pre-wrap;
}

.keyword {
  color: #ff7b00;
}

.string {
  color: #feca57;
}

.console {
  color: #4ec9b0;
}

.cursor {
  display: inline-block;
  vertical-align: text-bottom;
  width: 0.5em;
  animation: blink 1s step-end infinite;
}

.banner {
  background-color: #1e1e1e;
  padding: 24px;
  width: 100%;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lilita One", sans-serif;
  background-color: black;
}
.textContainer {
  text-align: justify;
  text-justify: inter-word;
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .code-block {
    font-size: 14px;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 576px) {
  .code-block {
    font-size: 12px;
    line-height: 1.2;
  }
}

.description {
  overflow: auto;
}

/* style the scrollbar */
.description::-webkit-scrollbar {
  width: 8px;
}

.description::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.description::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.description::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
